.vacancy-causes__row 
    display: flex
    flex-wrap: wrap
    margin-right: -15px
    margin-left: -15px
    max-width: 100%
    justify-content: space-between


//.vacancy-causes__item-wrapper 
//
//.vacancy-causes-item

.vacancy-causes-item__img-wrapper 
    text-align: center 
.vacancy-causes-item__img
    max-width: 100px 
.vacancy-causes-item__text
    font-size: 0.9em 
    padding-left: 10px
    padding-right: 10px
    font-weight: 600
    text-align: center
    color: #2b09c2
    line-height: 1.1

.text-benefits
    margin: 30px
    font-weight: 900
    font-size: 2em
    text-align: center
    color: #2216cc
    line-height: 1.1
@media screen and (max-width: 1000px)
    .text-benefits
        font-size: 1.4em
    


