.textblock
    // List
    ol,
    ul
        color: #3e3e3e
        font-size: 16px
        margin: 30px 0 30px 50px
        line-height: 1.4

    li
        &:not(:last-child)
            margin-bottom: 5px

    ol
        list-style: decimal

    ul
        list-style: disc

    p
        color: #3e3e3e
        font-size: 16px
        font-weight: 400
        line-height: 1.4

        &:not(:last-child)
            margin-bottom: 15px

        blockquote
            border-left: 3px solid $blue
            padding-left: 30px
            margin: 30px 0 30px 25px
            font-size: 1rem
            font-style: italic
