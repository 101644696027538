/*!
 * Bootstrap v5.0.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Required
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// Layout & components
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
// @import "../../../node_modules/bootstrap/scss/nav";
// @import "../../../node_modules/bootstrap/scss/navbar";
// @import "../../../node_modules/bootstrap/scss/card";
// @import "../../../node_modules/bootstrap/scss/accordion";
// @import "../../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../../node_modules/bootstrap/scss/pagination";
// @import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
// @import "../../../node_modules/bootstrap/scss/progress";
// @import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
// @import "../../../node_modules/bootstrap/scss/toasts";
// @import "../../../node_modules/bootstrap/scss/modal";
// @import "../../../node_modules/bootstrap/scss/tooltip";
// @import "../../../node_modules/bootstrap/scss/popover";
// @import "../../../node_modules/bootstrap/scss/carousel";
// @import "../../../node_modules/bootstrap/scss/spinners";

// Helpers
// @import "../../../node_modules/bootstrap/scss/helpers";
// @import "../../../node_modules/bootstrap/scss/helpers/clearfix";
// @import "../../../node_modules/bootstrap/scss/helpers/colored-links";
@import "../../../node_modules/bootstrap/scss/helpers/ratio";
@import "../../../node_modules/bootstrap/scss/helpers/position";
// @import "../../../node_modules/bootstrap/scss/helpers/visually-hidden";
// @import "../../../node_modules/bootstrap/scss/helpers/stretched-link";
// @import "../../../node_modules/bootstrap/scss/helpers/text-truncation";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

/**
 * Override bootstrap styles
 * My custom styles
 */

// @import "./btn";
// @import "./form-group";
// @import "./form-control";
// @import "./form-row";
