.module
    margin-bottom: 30px

    &__title
        font-size: 2rem
        margin-bottom: 30px
        color: #388d35
        @include media-breakpoint-up(lg)
        font-size: 1.5rem
.container_iner-zagruzka

    display: flex
    flex-wrap: wrap
    justify-content: space-between
    text-align: center




.zagruzka
    text-align: center
    background-color: #81e211
    border-radius: 10px

    text-decoration: none
    color: #000
    min-width: 300px
    max-width: 300px
    height: 85px
    margin-top: 20px
    font-weight: 900
    margin-left: 5px


    &:hover
        &::after
            border-top-color: $primary
            border-right-color: $primary

        
