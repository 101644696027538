.vakansyi_inner
    width: 100%
table
    border-collapse: collapse
    justify-content: space-between
    width: 100%
tr
    font-weight: 500
    font-size: 0.9rem
tr:hover
    background: #646464
    color: #f5e7e7
td
    padding: 10px
    border: 2px solid grey
.i_title
    font-size: 1.2rem    
    font-weight: 600
    color: #19962a 
    margin-bottom: 10px
