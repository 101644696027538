.sidebar
    &--yellow-offset
        position: relative
        padding-top: 150px

        &::before
            background-color: rgba($yellow, 0.2)
            content: ""
            position: absolute
            top: 0
            left: -70px
            right: 0
            display: block
            height: 100px
            z-index: 0

    &--left
        @include media-breakpoint-up(lg)
            position: relative
            left: -75%

.aside-ner

    .text_PS
        font-size: 1.5rem
        font-weight: 500
        align-items: center
        color: #49975A
    .text_PSS
        font-size: 1.1rem
        font-weight: 700
        align-items: center
        color: #49975A
    .text_DD
        font-size: 1.2rem
        font-weight: 700
        color: #0A3EA4

    .aside_btn
        position: relative
        background: #BF7633
        border-radius: 5px
    .aside_btn:hover
        background-color: #360dec

    a.btn_top
        color: #B4F6C6
        text-decoration: none
    a.btn_top:hover
        color: #f8f5f5
        font-weight: 500
.text_agit
    font-size: 1.2rem
    font-weight: 600
    margin-top: 10px
    color: #07056d
