.mainmenu
    $self: &

    /**
     * All viewports
     */
    &__list
        font-size: 16px
        font-weight: 500
        line-height: normal

        li
            a
                text-decoration: none
                &:hover
                    color: $primary

            &.is-active
                a
                    color: $primary

    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        background-color: $white
        position: fixed
        top: 0
        left: 0
        bottom: 0
        overflow-y: auto
        padding: 20px
        transform: translateX(-100%)
        transition: transform 0.3s ease
        width: 260px
        z-index: 102

        &.is-active
            transform: translateX(0)
            transition: transform 0.3s ease

        &__close-btn
            position: absolute
            top: 12px
            right: 12px
            font-size: 14px

        &__list
            font-weight: 500

            li
                a
                    color: $gray-600
                    &:hover
                        text-decoration: none

                &:not(:last-child)
                    margin-bottom: 20px

    /**
     * Desktop
     */
    @include media-breakpoint-up(lg)
        &__list
            display: flex
            justify-content: flex-end

            > #{$self}__item
                &:not(:last-child)
                    margin-right: 20px

                    @include media-breakpoint-up(xl)
                        margin-right: 40px

                > a
                    padding-top: 5px
                    padding-bottom: 5px

        &__item
            a
                color: $dark
                position: relative
                display: block
                transition: color .2s ease

                &:hover
                    text-decoration: none
                    transition: color .2s ease

        // Dropdowns
        &__dropdown
            background-color: $white
            box-shadow: 0 0 5px 0 rgba($black, 0.2)
            padding: 10px 15px
            font-size: 14px
            position: absolute
            top: 30px
            left: 0
            margin-top: 15px
            opacity: 0
            transition: opacity, margin-top .2s ease
            min-width: 150px

            #{$self}__item
                a
                    display: block
                    padding-top: 8px
                    padding-bottom: 8px

        // Parent
        &__item--parent
            position: relative
            z-index: 10

            &:hover
                #{$self}
                    &__dropdown
                        transition: opacity, margin-top .2s ease
                        margin-top: 0
                        opacity: 1

            > a
                display: flex
                align-items: center

                &::after
                    border-top: 2px solid $dark
                    border-right: 2px solid $dark
                    content: ""
                    display: block
                    margin-left: 8px
                    transform: rotate(135deg)
                    height: 7px
                    width: 7px

                &:hover
                    &::after
                        border-top-color: $primary
                        border-right-color: $primary
