.form-control.form-control-lg
    margin: 20px 0 0
    color: #616161
.wrap_vakansi-form
    display: flex
    flex-wrap: wrap
#phone
    margin-bottom: 25px
.form-title
    text-align: center
    color: #0f8835
    line-height: 0.9
    margin: 0 0 10px
    margin-top: 0px

.select-form
    font-size: 1rem
    padding: 0 0 0 
    margin: 0
    width: 5px
    color: #373737
    font-weight: 600
.bootom_click
    margin-top: 15px
    font-size: 0.9em
    line-height: 0.9
    text-align: center
    font-weight: 600


.form
    
    $self: &

    &__group
        position: relative
  

        &:not(:last-child)
            margin-bottom: 0px

    // Label
    &__label
        color: #9c9c9c
        position: absolute
        left: 15px
        top: 50%
        pointer-events: none
        font-size: 14px
        line-height: 1.2
        transform: translateY(-50%)
        transition: all 0.2s ease
        margin: 0
        z-index: 5

        &--textarea
            transform: none
            top: 15px

    // Input
    &__control
        border: 1px solid rgba($black, 0.5)
        padding: 0 15px
        font-size: 16px
        transition: border-color 0.3s ease
        height: 48px
        width: 100%

        &::placeholder
            text-transform: uppercase

        &:focus
            border-color: rgba($black, 1)
            transition: border-color 0.3s ease

        &--textarea
            padding: 15px
            height: 120px

    &__submit
        align-items: center
        display: flex
        padding-top: 0
        padding-bottom: 0
        justify-content: center
        font-size: 18px
        text-transform: uppercase
        height: 48px


    // Focus state
    .is-focused
        #{$self}
            &__control
                border-color: $gray-800
                transition: border-color .2s ease

            &__label
                font-size: 11px
                top: 8px
                bottom: auto
                transition: all 0.2s ease

                &--textarea
                    top: 4px
